import squirrel from "../img/squirrel.gif";

function Image(props) {
  const correctAnswer = props.selectedChoice === props.correct;
  if (correctAnswer && props.score === props.seriesLength) {
    return (
      <iframe
        src={props.embedUrl}
        title="YouTube video player"
        frameBorder="0"
        allow="autoplay; encrypted-media;"> 
      </iframe>
  );
  }

  if (correctAnswer && props.score < props.seriesLength) {
    return (
      <img
      src={squirrel}
      alt="Dancing squirrel"
      className="Squirrel"
    />
  );
  }

  return null;
}

export default Image;
