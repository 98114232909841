import "../css/Button.css";

function ChoiceButton(props) {
  const backgroundColor =
    props.selectedChoice === null
      ? "white"
      : props.value === props.correct
      ? "green"
      : "red";
  return (
    <div
      className="Button"
      style={{ backgroundColor }}
      onClick={() => props.dispatch({ type: "choice", value: props.value })}
    >
      {props.value}
    </div>
  );
}

export default ChoiceButton;
