function Sum(props) {
  let sign = props.mode;

  if (!["+", "-", "×"].includes(props.mode)) {
    sign = "×";
  }

  return (
    <div className="Sum">
      {props.sum.left} {sign} {props.sum.right} ={" "}
      {props.selectedChoice ? props.sum.correct : "??"}
    </div>
  );
}

export default Sum;
