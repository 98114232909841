import "./css/App.css";
import ModeButton from "./components/ModeButton";
import ChoiceButton from "./components/ChoiceButton";
import RefreshButton from "./components/RefreshButton";
import Sum from "./components/Sum";
import { useReducer } from "react";
import { pickNumber, getChoices } from "./util/utils";
import Score from "./components/Score";
import Image from "./components/Image";

/**
 * Config: what numbers can be selected left and right
 */
const leftNumbers = [0, 1, 2, 3, 4, 5];
const rightNumbers = [0, 1, 2, 3, 4, 5]; // only when for when mode is '+' or '-'
const seriesLength = 5; // number of answers in a row that should be correct
const embedUrl = 'https://www.youtube-nocookie.com/embed/hdcTmpvDO0I?controls=0&amp;start=5&amp;rel=0';

/**
 * Initial state
 */
const initialLeft = pickNumber(leftNumbers);
const initialRight = pickNumber(rightNumbers);
const initialCorrect = initialLeft + initialRight;

const initialState = {
  playing: false,
  mode: "+", // either '+', '-', '×', '1', '2', '3' for the tables...
  selectedChoice: null,
  choices: getChoices(initialCorrect, "+"),
  sum: {
    left: initialLeft,
    right: initialRight,
    result: "??",
    correct: initialCorrect,
  },
  score: 0,
  showScore: true,
  embedUrl: '' // empty at first. Set when iframe shown and unset when sum refreshed
};

/**
 * The single reducer that calculates state changes
 * when an action comes in
 * @param {*} state
 * @param {*} action
 * @returns
 */
function reducer(state, action) {
  console.log(action);
  switch (action.type) {
    case "setMode":
      return { ...state, mode: action.value };
    case "refresh":
      // create new sum
      const left = pickNumber(leftNumbers);
      let right = pickNumber(rightNumbers);
      let correct = left + right;

      if (state.mode === "-") {
        correct = left - right;
      }

      if (state.mode === "×") {
        correct = left * right;
      }

      // if we're doing table, right number is always the table you're practicing
      if (!["+", "-", "×"].includes(state.mode)) {
        right = state.mode;
        correct = left * right;
      }

      return {
        ...state,
        selectedChoice: null,
        choices: getChoices(correct, state.mode),
        sum: {
          left,
          right,
          result: "??",
          correct,
        },
        score: state.score === seriesLength ? 0 : state.score,
        showScore: true,
        playing: state.score === seriesLength || state.sum.result !== correct ? false : true,
        embedUrl: '',
      };
    case "choice":
      const updatedScore = action.value === state.sum.correct ? state.score + 1 : 0;
      const updatedEmbedUrl = updatedScore === seriesLength ? embedUrl : '';
      return {
        ...state,
        playing: true,
        selectedChoice: action.value,
        score: updatedScore,
        embedUrl: updatedEmbedUrl,
        showScore: updatedEmbedUrl === '',
      };

    default:
      throw new Error();
  }
}

/**
 * App component
 * @returns
 */
function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <div className="App">
      <div className="buttonsHorizontal1">
        <ModeButton value="+" mode={state.mode} dispatch={dispatch} />
        <ModeButton value="-" mode={state.mode} dispatch={dispatch} />
        <ModeButton value="×" mode={state.mode} dispatch={dispatch} />
      </div>

      <div className="buttonsHorizontal2">
        <ModeButton value="1" mode={state.mode} dispatch={dispatch} />
        <ModeButton value="2" mode={state.mode} dispatch={dispatch} />
        <ModeButton value="3" mode={state.mode} dispatch={dispatch} />
        <ModeButton value="4" mode={state.mode} dispatch={dispatch} />
        <ModeButton value="5" mode={state.mode} dispatch={dispatch} />
      </div>

      <div className="buttonsHorizontal3">
        <ModeButton value="6" mode={state.mode} dispatch={dispatch} />
        <ModeButton value="7" mode={state.mode} dispatch={dispatch} />
        <ModeButton value="8" mode={state.mode} dispatch={dispatch} />
        <ModeButton value="9" mode={state.mode} dispatch={dispatch} />
        <ModeButton value="10" mode={state.mode} dispatch={dispatch} />
      </div>

      <h1>Otto's rekenspel!</h1>

      <Sum
        mode={state.mode}
        sum={state.sum}
        selectedChoice={state.selectedChoice}
      />

      <div className="choices">
        <ChoiceButton
          value={state.choices[0]}
          selectedChoice={state.selectedChoice}
          correct={state.sum.correct}
          dispatch={dispatch}
        />

        <ChoiceButton
          value={state.choices[1]}
          selectedChoice={state.selectedChoice}
          correct={state.sum.correct}
          dispatch={dispatch}
        />

        <ChoiceButton
          value={state.choices[2]}
          selectedChoice={state.selectedChoice}
          correct={state.sum.correct}
          dispatch={dispatch}
        />

        <RefreshButton dispatch={dispatch} />
      </div>

      <Score 
        showScore={state.showScore}
        score={state.score}
        playing={state.playing}
        seriesLength={seriesLength}
      />

      <Image 
        score={state.score}
        selectedChoice={state.selectedChoice}
        correct={state.sum.correct}
        embedUrl={state.embedUrl}
        seriesLength={seriesLength}
      />

      <div className="version">v4</div>
    </div>
  );
}

export default App;
