/**
 * Helper
 * @param {} choices
 * @returns
 */
export const pickNumber = (choices) => {
  var index = Math.floor(Math.random() * choices.length);
  return choices[index];
};

/**
 * Get choices including the correct answer and
 * 2 random choices
 * @param {*} result
 * @param {*} gameMode
 * @returns
 */
export const getChoices = (result, gameMode) => {
  var choicesOrdered = [];

  // avoid negative choices when gameMode is '+'
  if (gameMode === "+" && result === 0) {
    choicesOrdered = [0, 1, 2];
  } else {
    choicesOrdered = [result - 1, result, result + 1];
  }

  // get random index between 0, 1 or 2
  var index = Math.floor(Math.random() * 3);
  var choicesMixed = [];

  for (let i = 0; i < 3; i++) {
    choicesMixed[(index + i) % 3] = choicesOrdered[i];
  }

  return choicesMixed;
};
