import "../css/Button.css";

function ModeButton(props) {
  return (
    <div
      className="Button"
      onClick={() => {
        props.dispatch({ type: "setMode", value: props.value });
        props.dispatch({ type: "refresh" });
      }}
      style={{
        backgroundColor: props.mode === props.value ? "lightgrey" : "white",
      }}
    >
      {props.value}
    </div>
  );
}

export default ModeButton;
