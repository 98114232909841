import "../css/Button.css";

function RefreshButton(props) {
  return (
    // button with background image
    <div
      className="RefreshButton"
      onClick={() => props.dispatch({ type: "refresh" })}
    />
  );
}

export default RefreshButton;
