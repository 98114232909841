function Score(props) {
  return (
    <div
      className="Score"
      style={{color: !props.playing ? 'black' : (props.score === 0 ? 'red' : 'green')}}
    >
      {props.showScore && <span>{props.score} / {props.seriesLength}</span>}
    </div>
  );
}

export default Score;
